import { get, post } from '../request';

// 创建字典
export const dicCreate = params => post(`/api/v1/dic/create`, params);

// 字典信息列表
export const dicGetList = params => post(`/api/v1/dic/getList`, params);

// 更改字典状态
export const dicUpdateStatus = params => post(`/api/v1/dic/updateStatus`, params);

// 更新字典数据
export const dicUpdate = params => post(`/api/v1/dic/update`, params);