import { get, post } from '../request';

//列表
export const clinicList = params => post(`/api/v1/clinic/getList`, params);

//列表
export const keyPlusGetClinicListByDoctorId = params => get(`/api/v1/clinic/keyPlusGetClinicListByDoctorId/${params}`);

//编号
export const clinicProjectno = params => get(`/api/v1/admin/home/projectno?types=clinic`);

//医生列表
export const getDoctorIdList = params => post(`/api/v1/clinic/getDoctorIdList`, params);

//添加
export const clinicCreate = params => post(`/api/v1/clinic/create`, params);

//view
export const getClinicROW = params => get(`/api/v1/clinic/getClinic/${params}`);

//更新
export const updateClinic = (params, params2) => post(`/api/v1/clinic/update/${params}`, params2);

//删除
export const deleteclinic = params => post(`/api/v1/clinic/delete`, params);
